const hasQueryParams = (url) => url.includes('?');
const base64AddPadding = (encoded) => {
    return base64RemovePadding(encoded) + Array(((4 - (encoded.length % 4)) % 4) + 1).join('=');
};

const base64RemovePadding = (str) => {
    return str.replace(/={1,2}$/, '');
};

export const encodeString = (str) => {
    return base64RemovePadding(btoa(str));
};

/**
 * @param {string} str
 */
export const decodeString = (str) => {
    if (str.includes('=')) {
        return atob(str);
    }

    return atob(base64AddPadding(str));
};
const openGlobalModal = (modalId) => {
    window.dispatchEvent(
        new CustomEvent('openGlobalModal', {
            detail: {
                modalId,
            },
        })
    );
};

const getModalIdFromCurrentQuery = () => {
    const params = new URLSearchParams(window.location.search);

    try {
        return decodeString(params.get('omodal') ?? '');
    } catch (_) {
        return null;
    }
};

export const InitializeModalListeners = () => {
    window.openGlobalModal = openGlobalModal;

    let modalId = getModalIdFromCurrentQuery();

    if (modalId) {
        openGlobalModal(modalId);
    }

    document.querySelectorAll('[data-show-modal]').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            openGlobalModal(item.getAttribute('data-show-modal'));

            return false;
        });
    });

    document.querySelectorAll('a').forEach((item) => {
        if (hasQueryParams(item.href)) {
            const url = new URL(item.href);

            if (url.searchParams.has('omodal')) {
                item.addEventListener('click', (e) => {
                    let hrefURL = new URL(item.href);
                    let pageURL = new URL(window.location);
                    if (hrefURL.host === pageURL.host) {
                        e.preventDefault();

                        const encodedModalId = (url.searchParams.get('omodal') ?? '').replace('?omodal=', '');
                        const modalId = decodeString(encodedModalId);
                        openGlobalModal(modalId);

                        return false;
                    }
                });
            }
        }
    });
};
